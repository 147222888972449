import algoliasearch, { type SearchClient } from "algoliasearch"
import { performAlgoliaTextSearch, buildAlgoliaSearchController } from "@firecms/firebase"

interface SearchParams {
	path: string
	searchString: string
}

const ALGOLIA_APP_ID = import.meta.env.VITE_ALGOLIA_APP_ID as string
const ALGOLIA_SEARCH_KEY = import.meta.env.VITE_ALGOLIA_SEARCH_KEY as string

const client: SearchClient | undefined = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY)

const brandsIndex = client?.initIndex("brands-dev")
const courtCasesIndex = client?.initIndex("courtCases-dev")
const defendantsIndex = client?.initIndex("defendants-dev")
const firmsIndex = client?.initIndex("firms-dev")
const settlementsIndex = client?.initIndex("settlements-dev")
const extractIndex = client?.initIndex("extract-dev")
const extractErrorIndex = client?.initIndex("extractError-dev")
const generalQuestionsIndex = client?.initIndex("generalQuestions-dev")
const usersIndex = client?.initIndex("users-dev")

export const textSearchController = buildAlgoliaSearchController({
	isPathSupported: (path: string) => {
		return [
			"_brands",
			"_defendants",
			"_courtCases",
			"_firms",
			"_settlements",
			"extract",
			"extractErrors",
			"generalQuestions",
			"users",
		].includes(path)
	},
	search: ({ path, searchString }: SearchParams) => {
		if (path === "_brands" && brandsIndex) {
			return performAlgoliaTextSearch(brandsIndex, searchString)
		}
		if (path === "_courtCases" && courtCasesIndex) {
			return performAlgoliaTextSearch(courtCasesIndex, searchString)
		}
		if (path === "_defendants" && defendantsIndex) {
			return performAlgoliaTextSearch(defendantsIndex, searchString)
		}
		if (path === "_firms" && firmsIndex) {
			return performAlgoliaTextSearch(firmsIndex, searchString)
		}
		if (path === "_settlements" && settlementsIndex) {
			return performAlgoliaTextSearch(settlementsIndex, searchString)
		}
		if (path === "extract" && extractIndex) {
			return performAlgoliaTextSearch(extractIndex, searchString)
		}
		if (path === "extractErrors" && extractErrorIndex) {
			return performAlgoliaTextSearch(extractErrorIndex, searchString)
		}
		if (path === "generalQuestions" && settlementsIndex) {
			return performAlgoliaTextSearch(generalQuestionsIndex, searchString)
		}
		if (path === "users" && usersIndex) {
			return performAlgoliaTextSearch(usersIndex, searchString)
		}
		console.error("index not initialized or path not supported")
		return undefined
	},
})

export default textSearchController
