import { Typography } from "@firecms/ui"

type Props = {
	title: string
	children: React.ReactNode
}

const ExpandableTitle: React.FC<Props> = ({ title, children }) => (
	<div className="flex items-center gap-2">
		<Typography variant="label" color="secondary">
			{children}
		</Typography>
		<Typography variant="label" color="secondary" className="font-medium block text-sm">
			{title}
		</Typography>
	</div>
)

export default ExpandableTitle
