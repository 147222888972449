import { buildCollection } from "@firecms/core"
import type { ADMIN } from "types"
import { SpecificAnswersCollection } from "./subcollections/specificAnswers"
import { AdminDetail } from "../components/AdminDetail"
import { AdminField } from "../properties/AdminField"
import DateUpdated from "../properties/DateUpdated"
import DateCreated from "../properties/DateCreated"
import AnswerContent from "../properties/AnswerContent"
import QATypeProperty from "../properties/QATypeProperty"

export const UsersCollection = buildCollection<ADMIN.User>({
	id: "users",
	name: "Users",
	singularName: "User",
	path: "users",
	icon: "account_circle",
	group: "public",
	selectionEnabled: false,
	textSearchEnabled: true,
	defaultSize: "xs",
	subcollections: [SpecificAnswersCollection],
	permissions: () => ({
		read: true,
		edit: true,
		create: true,
		delete: true,
	}),
	properties: {
		uid: {
			name: "uid",
			dataType: "string",
			disabled: { hidden: true },
		},
		email: {
			name: "Email",
			dataType: "string",
			email: true,
			readOnly: true,
		},
		hasCompletedForm: {
			name: "Has Completed Form",
			description: "Did this user finish the question form?",
			dataType: "boolean",
		},
		hiddenSettlements: {
			name: "Hidden Settlements",
			description: "A list of settlements the user has manually hidden",
			dataType: "array",
			expanded: false,
			of: {
				name: "Settlement",
				dataType: "reference",
				path: "settlements",
			},
		},
		generalAnswers: {
			name: "General Answers",
			description: "The list of the user's general answers.",
			dataType: "array",
			of: {
				name: "Answer",
				dataType: "map",
				expanded: false,
				properties: {
					question: {
						description: "the question this answers.",
						dataType: "reference",
						path: "generalQuestions",
						previewProperties: ["text", "type"],
					},
					type: QATypeProperty,
					content: AnswerContent,
					dateCreated: DateCreated,
					dateUpdated: DateUpdated,
				},
			},
		},
		legalAcceptance: {
			name: "Legal Acceptance",
			description: "When did the user accept the terms and conditions?",
			dataType: "array",
			of: {
				name: "Event",
				dataType: "map",
				expanded: false,
				properties: {
					version: {
						name: "Version",
						dataType: "string",
					},
					acceptedAt: {
						name: "Accepted At",
						dataType: "date",
					},
				},
			},
		},

		// @ts-ignore
		admin: props => {
			return {
				name: "Admin Status",
				dataType: "boolean",
				hideFromCollection: true,
				Field: AdminDetail,
				customProps: {
					uid: props.entityId,
					email: props.values.email,
				},
			}
		},
		dateUpdated: DateUpdated,
		dateCreated: DateCreated,
	},
	//@ts-ignore
	additionalFields: [AdminField],
})
