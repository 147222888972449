import type { AspectRatio } from "types/Enums/AspectRatio"
import type { ImageAuthor } from "types/Enums/ImageAuthor"
import type { ImageVariant } from "types/Enums/ImageVariant"

export const imgDomain = (projectId: string) => {
	return `https://${projectId}.imgix.net`
}

export const imgPath = (settlementId: string, author: ImageAuthor, option: number, variant = "upscale") => {
	return `/settlements/${settlementId}/images/${author}-${option.toString()}-${variant}.jpg`
}

export const imgQuery = (aspect: AspectRatio, width: number, quality: number) => {
	return `?${calculateAspect(aspect)}&w=${width.toString()}&q=${quality.toString()}`
}

export const imgUrl = (
	settlementId: string,
	projectId = "claimgem-dev",
	author: ImageAuthor = "o",
	option = 0,
	variant: ImageVariant = "upscale",
	aspect: AspectRatio = "1x1",
	width = 1080,
	quality = 80
) => {
	const domain = imgDomain(projectId)
	const path = imgPath(settlementId, author, option, variant)
	const query = imgQuery(aspect, width, quality)
	return `${domain}${path}${query}`
}

const calculateAspect = (aspect: AspectRatio) => {
	if (aspect === "4x5") return "rect=870,0,2867,3584"
	if (aspect === "1x1") return "rect=768,256,3072,3072"
	if (aspect === "16x9") return "rect=0,496,4608,2592"
	if (aspect === "19x10") return "rect=0,582,4608,2420"
	throw new Error("aspect must be 4x5, 1x1, 16x9, or 19x10")
}
