import { buildCollection } from "@firecms/core"
import type { ADMIN } from "types"
import { SettlementsCollection } from "../settlements"
import DateCreated from "../../properties/DateCreated"
import ImageAuthor from "../../properties/ImageAuthor"
import ImageOption from "../../properties/ImageOption"

export const Page = buildCollection<ADMIN.Page>({
	id: "pages",
	name: "Pages",
	singularName: "Page",
	path: "pages",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		pageName: {
			dataType: "string",
			name: "Page Name",
		},
		markdown: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
		metadata: {
			name: "Raw Response",
			hideFromCollection: true,
			dataType: "map",
			readOnly: true,
			keyValue: true,
		},
	},
})

export const Firecrawl = buildCollection<ADMIN.Firecrawl>({
	id: "firecrawl",
	name: "Crawl",
	singularName: "Crawl",
	path: "firecrawl",
	description: "A history of the crawled settlement website",
	defaultSize: "xl",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	subcollections: [Page],
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
	},
})

export const OptimizedContent = buildCollection<ADMIN.OptimizedContent>({
	id: "optimizedContent",
	name: "Content",
	singularName: "Content",
	path: "optimizedContent",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	properties: {
		dateCreated: DateCreated,
		optimizedContent: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
	},
})

export const LongSummaryCollection = buildCollection<ADMIN.LongSummaryCollection>({
	id: "longSummaryCollection",
	name: "Long Summary",
	singularName: "Long Summary",
	path: "longSummaryCollection",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	properties: {
		dateCreated: DateCreated,
		longSummary: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
	},
})

export const AllegationsSummaryCollection = buildCollection<ADMIN.AllegationsSummaryCollection>({
	id: "allegationsSummaryCollection",
	name: "Allegations",
	singularName: "Allegations",
	path: "allegationsSummaryCollection",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	properties: {
		dateCreated: DateCreated,
		allegationsSummary: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
	},
})

export const EligibilitySummaryCollection = buildCollection<ADMIN.EligibilitySummaryCollection>({
	id: "eligibilitySummaryCollection",
	name: "Eligibility",
	singularName: "Eligibility",
	path: "eligibilitySummaryCollection",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	properties: {
		dateCreated: DateCreated,
		eligibilitySummary: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
		response: {
			name: "Raw Response",
			hideFromCollection: true,
			dataType: "map",
			readOnly: true,
			keyValue: true,
		},
	},
})

export const ImageCollection = buildCollection<ADMIN.ImageCollection>({
	id: "imageCollection",
	name: "Images",
	singularName: "Image",
	path: "imageCollection",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	properties: {
		imageUrls: {
			dataType: "array",
			readOnly: true,
			of: {
				name: "Images",
				dataType: "map",
				properties: {
					alt: {
						name: "Alt",
						description: "The prompt and image description",
						dataType: "string",
					},
					author: ImageAuthor,
					option: ImageOption,
				},
			},
		},
		dateCreated: DateCreated,
		response: {
			name: "Raw Response",
			hideFromCollection: true,
			dataType: "map",
			readOnly: true,
			keyValue: true,
		},
	},
})

export const ProofCollection = buildCollection<ADMIN.ProofCollection>({
	id: "proofCollection",
	name: "Proof",
	singularName: "Proof",
	path: "proofCollection",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	properties: {
		dateCreated: DateCreated,
		proof: {
			dataType: "map",
			properties: {
				isRequired: {
					name: "Is Required",
					dataType: "boolean",
					readOnly: true,
				},
				proofSummary: {
					name: "Proof Summary",
					dataType: "string",
					markdown: true,
					readOnly: true,
				},
			},
		},
		response: {
			name: "Raw Response",
			hideFromCollection: true,
			dataType: "map",
			readOnly: true,
			keyValue: true,
		},
	},
})

export const SlugCollection = buildCollection<ADMIN.SlugCollection>({
	id: "slugCollection",
	name: "Slug",
	singularName: "Slug",
	path: "slugCollection",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	properties: {
		dateCreated: DateCreated,
		slug: {
			dataType: "string",
			readOnly: true,
		},
		response: {
			name: "Raw Response",
			hideFromCollection: true,
			dataType: "map",
			readOnly: true,
			keyValue: true,
		},
	},
})

export const Core = buildCollection<ADMIN.Core>({
	id: "core",
	name: "Core",
	singularName: "Core",
	path: "core",
	description: "The base settlement object (without the form)",
	defaultSize: "xl",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	properties: {
		dateCreated: DateCreated,
		settlement: {
			name: "Settlement Object",
			dataType: "map",
			keyValue: true,
			readOnly: true,
		},
	},
})

export const Complete = buildCollection<ADMIN.Settlement>({
	...SettlementsCollection,
	id: "complete",
	name: "Complete",
	singularName: "Complete",
	description: "A test object all the steps are written to",
	path: "complete",
	initialSort: ["dateUpdated", "desc"],
	selectionEnabled: false,
	defaultSize: "xs",
	permissions: () => ({
		read: true,
		edit: false,
		create: false,
		delete: true,
	}),
	properties: {
		...SettlementsCollection.properties,
	},
})
