import { buildCollection, buildProperty } from "@firecms/core"
import type { ADMIN } from "types"
import { DuplicateCheckName } from "../components/DuplicateCheckName"
import DateCreated from "../properties/DateCreated"
import DateUpdated from "../properties/DateUpdated"

export const _BrandsCollection = buildCollection<ADMIN.Brand>({
	id: "_brands",
	name: "Brands (Private)",
	singularName: "Brand (Private)",
	description: "A list of brands owned by defendants",
	path: "_brands",
	icon: "category",
	group: "private",
	defaultSize: "m",
	selectionEnabled: true,
	textSearchEnabled: true,
	initialSort: ["name", "asc"],
	permissions: () => ({
		read: true,
		edit: true,
		create: true,
		delete: true,
	}),
	properties: {
		name: {
			name: "Name",
			description: "A trademark or other name that the consumer will recognize",
			longDescription: "e.g. 'Facebook', 'Oculus'",
			dataType: "string",
		},
		image: buildProperty({
			name: "Image",
			dataType: "string",
			storage: {
				storagePath: context => {
					return `brands/${context.entityId}/`
				},
				acceptedFiles: ["image/*"],
				maxSize: 1024 * 1024,
				metadata: {
					cacheControl: "max-age=1000000",
				},
				fileName: "image",
			},
		}),
		dateUpdated: DateUpdated,
		dateCreated: DateCreated,
	},
	additionalFields: [DuplicateCheckName],
})
