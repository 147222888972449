import { buildProperty } from "@firecms/core"
import type { ADMIN } from "types"

const ImageOption = buildProperty<ADMIN.Settlement["image"]["option"]>({
	name: "Option",
	dataType: "number",
	enumValues: [
		{ label: "0", id: 0 },
		{ label: "1", id: 1 },
		{ label: "2", id: 2 },
		{ label: "3", id: 3 },
	],
})

export default ImageOption
