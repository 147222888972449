import { buildCollection } from "@firecms/core"
import type { ADMIN } from "types"
import {
	Firecrawl,
	OptimizedContent,
	LongSummaryCollection,
	AllegationsSummaryCollection,
	EligibilitySummaryCollection,
	ProofCollection,
	ImageCollection,
	Core,
	Complete,
	SlugCollection,
} from "./subcollections/extractSteps"
import DateUpdated from "../properties/DateUpdated"
import DateCreated from "../properties/DateCreated"

export const ExtractCollection = buildCollection<ADMIN.Extract>({
	id: "extract",
	name: "Extract Data",
	singularName: "Extract Data",
	description: "Extract data pipeline",
	path: "extract",
	group: "tools",
	icon: "format_list_numbered",
	textSearchEnabled: true,
	initialSort: ["dateUpdated", "desc"],
	permissions: () => ({
		read: true,
		edit: true,
		create: true,
		delete: true,
	}),
	propertiesOrder: [
		"url",
		"skipExtraction",
		"dateUpdated",
		"dateCreated",
		"subcollection:firecrawl",
		"subcollection:optimizedContent",
		"subcollection:longSummaryCollection",
		"subcollection:allegationsSummaryCollection",
		"subcollection:eligibilitySummaryCollection",
		"subcollection:proofCollection",
		"subcollection:imageCollection",
		"subcollection:slugCollection",
		"subcollection:core",
		"subcollection:complete",
	],
	subcollections: [
		Firecrawl,
		OptimizedContent,
		LongSummaryCollection,
		AllegationsSummaryCollection,
		EligibilitySummaryCollection,
		ProofCollection,
		ImageCollection,
		SlugCollection,
		Core,
		Complete,
	],
	properties: {
		url: {
			name: "URL",
			dataType: "string",
			url: true,
		},
		skipExtraction: {
			name: "Skip Extraction",
			dataType: "boolean",
			description: "Prevent this url from being run during an `extract` step. Useful for outdated or dead links.",
		},
		dateUpdated: DateUpdated,
		dateCreated: DateCreated,
	},
})
