import { buildCollection } from "@firecms/core"
import type { ADMIN } from "types"
import DateCreated from "../properties/DateCreated"
import DateUpdated from "../properties/DateUpdated"

export const Legal = buildCollection<ADMIN.Legal>({
	id: "legal",
	name: "Legal",
	singularName: "Legal",
	description: "The Terms of Use, Privacy Policy, and Cookie Policy that the user must accept.",
	path: "legal",
	group: "legal",
	icon: "policy",
	defaultSize: "xl",
	initialSort: ["dateCreated", "desc"],
	permissions: () => ({
		read: true,
		edit: true,
		create: true,
		delete: true,
	}),
	properties: {
		version: {
			name: "version",
			dataType: "string",
		},
		termsOfUse: {
			name: "Terms of Use",
			dataType: "string",
			markdown: true,
		},
		privacyPolicy: {
			name: "Privacy Policy",
			dataType: "string",
			markdown: true,
		},
		cookiePolicy: {
			name: "Cookie Policy",
			dataType: "string",
			markdown: true,
		},
		dateUpdated: DateUpdated,
		dateCreated: DateCreated,
	},
})
