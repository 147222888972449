import { buildProperty } from "@firecms/core"
import type { ADMIN } from "types"

const ImageAuthor = buildProperty<ADMIN.Settlement["image"]["author"]>({
	name: "Author",
	dataType: "string",
	enumValues: [
		{ label: "Dall-E 3 (OpenAI)", id: "o" },
		{ label: "Stable Diffusion (Stability AI)", id: "s" },
		{ label: "Flux (Black Forest Labs)", id: "f" },
	],
})

export default ImageAuthor
