import { buildCollection, buildProperty } from "@firecms/core"
import enumColors from "../utils/enumColors"
import type { ADMIN } from "types"
import { DuplicateCheckName } from "../components/DuplicateCheckName"
import DateCreated from "../properties/DateCreated"
import DateUpdated from "../properties/DateUpdated"

export const _CourtCasesCollection = buildCollection<ADMIN.CourtCase>({
	id: "_courtCases",
	name: "Court Cases (Private)",
	singularName: "Court Case (Private)",
	description: "The court cases associated.",
	path: "_courtCases",
	icon: "account_balance",
	group: "private",
	selectionEnabled: false,
	textSearchEnabled: true,
	defaultSize: "xs",
	initialSort: ["dateCreated", "asc"],
	permissions: () => ({
		read: true,
		edit: true,
		create: true,
		delete: true,
	}),
	properties: {
		name: {
			name: "Name",
			description: "",
			dataType: "string",
		},
		caseNumber: {
			name: "Case Number",
			description: "e.g. 2:19-cv-11745-GEM",
			previewAsTag: true,
			dataType: "string",
		},
		jurisdiction: {
			name: "Jurisdiction",
			description: "the court that the case was filed in",
			dataType: "string",
			enumValues: [
				{ label: "Federal", id: "federal", color: enumColors.red },
				{ label: "Other", id: "other", color: enumColors.blue },
				{ label: "Alabama", id: "AL", color: enumColors.gray },
				{ label: "Alaska", id: "AK", color: enumColors.gray },
				{ label: "Arizona", id: "AZ", color: enumColors.gray },
				{ label: "Arkansas", id: "AR", color: enumColors.gray },
				{ label: "California", id: "CA", color: enumColors.gray },
				{ label: "Colorado", id: "CO", color: enumColors.gray },
				{ label: "Connecticut", id: "CT", color: enumColors.gray },
				{ label: "Delaware", id: "DE", color: enumColors.gray },
				{ label: "District of Columbia", id: "DC", color: enumColors.gray },
				{ label: "Florida", id: "FL", color: enumColors.gray },
				{ label: "Georgia", id: "GA", color: enumColors.gray },
				{ label: "Hawaii", id: "HI", color: enumColors.gray },
				{ label: "Idaho", id: "ID", color: enumColors.gray },
				{ label: "Illinois", id: "IL", color: enumColors.gray },
				{ label: "Indiana", id: "IN", color: enumColors.gray },
				{ label: "Iowa", id: "IA", color: enumColors.gray },
				{ label: "Kansas", id: "KS", color: enumColors.gray },
				{ label: "Kentucky", id: "KY", color: enumColors.gray },
				{ label: "Louisiana", id: "LA", color: enumColors.gray },
				{ label: "Maine", id: "ME", color: enumColors.gray },
				{ label: "Maryland", id: "MD", color: enumColors.gray },
				{ label: "Massachusetts", id: "MA", color: enumColors.gray },
				{ label: "Michigan", id: "MI", color: enumColors.gray },
				{ label: "Minnesota", id: "MN", color: enumColors.gray },
				{ label: "Mississippi", id: "MS", color: enumColors.gray },
				{ label: "Missouri", id: "MO", color: enumColors.gray },
				{ label: "Montana", id: "MT", color: enumColors.gray },
				{ label: "Nebraska", id: "NE", color: enumColors.gray },
				{ label: "Nevada", id: "NV", color: enumColors.gray },
				{ label: "New Hampshire", id: "NH", color: enumColors.gray },
				{ label: "New Jersey", id: "NJ", color: enumColors.gray },
				{ label: "New Mexico", id: "NM", color: enumColors.gray },
				{ label: "New York", id: "NY", color: enumColors.gray },
				{ label: "North Carolina", id: "NC", color: enumColors.gray },
				{ label: "North Dakota", id: "ND", color: enumColors.gray },
				{ label: "Ohio", id: "OH", color: enumColors.gray },
				{ label: "Oklahoma", id: "OK", color: enumColors.gray },
				{ label: "Oregon", id: "OR", color: enumColors.gray },
				{ label: "Pennsylvania", id: "PA", color: enumColors.gray },
				{ label: "Rhode Island", id: "RI", color: enumColors.gray },
				{ label: "South Carolina", id: "SC", color: enumColors.gray },
				{ label: "South Dakota", id: "SD", color: enumColors.gray },
				{ label: "Tennessee", id: "TN", color: enumColors.gray },
				{ label: "Texas", id: "TX", color: enumColors.gray },
				{ label: "Utah", id: "UT", color: enumColors.gray },
				{ label: "Vermont", id: "VT", color: enumColors.gray },
				{ label: "Virginia", id: "VA", color: enumColors.gray },
				{ label: "Washington", id: "WA", color: enumColors.gray },
				{ label: "West Virginia", id: "WV", color: enumColors.gray },
				{ label: "Wisconsin", id: "WI", color: enumColors.gray },
				{ label: "Wyoming", id: "WY", color: enumColors.gray },
				{ label: "American Samoa", id: "AS", color: enumColors.gray },
				{ label: "Guam", id: "GU", color: enumColors.gray },
				{ label: "Northern Mariana Islands", id: "MP", color: enumColors.gray },
				{ label: "Puerto Rico", id: "PR", color: enumColors.gray },
				{ label: "United States Minor Outlying Islands", id: "UM", color: enumColors.gray },
				{ label: "Virgin Islands", id: "VI", color: enumColors.gray },
			],
		},
		dateFiled: buildProperty({
			name: "Date Filed",
			description: "When was the case first filed? https://pcl.uscourts.gov/pcl/pages/search/findCase.jsf",
			longDescription: "use PACER to find this",
			dataType: "date",
			mode: "date",
		}),
		dateUpdated: DateUpdated,
		dateCreated: DateCreated,
	},
	additionalFields: [DuplicateCheckName],
})
