import { buildCollection } from "@firecms/core"
import type { ADMIN } from "types"
import CustomId from "../components/CustomId"
import QATypeProperty from "../properties/QATypeProperty"
import DomainProperty from "../properties/DomainProperty"
import DateUpdated from "../properties/DateUpdated"
import DateCreated from "../properties/DateCreated"

export const GeneralQuestionsCollection = buildCollection<ADMIN.Question>({
	id: "generalQuestions",
	name: "General Questions",
	singularName: "general question",
	description: "A question used to determine settlement eligibility",
	path: "generalQuestions",
	icon: "help",
	group: "public",
	initialSort: ["order", "asc"],
	textSearchEnabled: true,
	defaultSize: "xs",
	permissions: () => ({
		read: true,
		edit: true,
		create: true,
		delete: true,
	}),
	properties: {
		isEnabled: {
			name: "Is Enabled",
			dataType: "boolean",
			description: "Should the question should be shown to new users?",
		},
		text: {
			name: "Question Title",
			description: "The main question text.",
			dataType: "string",
		},
		subtext: {
			name: "Question Subtitle",
			description: "Explanation or comment for why this question is being asked.",
			dataType: "string",
		},
		order: {
			name: "Order",
			description: "The order the question appears in.",
			dataType: "number",
			defaultValue: null,
		},
		domains: {
			name: "Domains",
			dataType: "array",
			description: "How can we identify who is eligible to apply?",
			expanded: false,
			of: DomainProperty,
		},
		icon: {
			name: "icon",
			dataType: "string",
			validation: {
				trim: true, // trim doesn't seem to work right now
			},
		},
		type: QATypeProperty,
		placeholder: ({ values }) => {
			const type = values.type
			if (type === "singleChoiceSelect") {
				return {
					name: "Placeholder",
					description: "the text displayed in a form control when the control has no value",
					dataType: "string",
				}
			}
			return null // Exclude the property entirely if it's not a choice type
		},
		//@ts-ignore
		options: ({ values }) => {
			const type = values.type
			if (type === "singleChoiceSelect" || type === "singleChoiceRadio") {
				return {
					name: "Options",
					description:
						"Used for multiple choice questions. Used for singleChoiceSelect, multipleChoiceSelect, singleChoiceRadio, multipleChoiceCheckboxes",
					dataType: "array",
					expanded: false,
					of: {
						name: "Option",
						dataType: "map",
						properties: {
							id: {
								name: "ID",
								dataType: "string",
								hideFromCollection: true,
								Field: CustomId,
							},
							label: {
								name: "Label",
								description: "Human readable label for the answer choice.",
								dataType: "string",
							},
							value: {
								name: "Value",
								description: "The value of this option.",
								dataType: "string",
							},
						},
					},
				}
			}
			if (type === "multipleChoiceCheckboxes") {
				return {
					name: "Options",
					description:
						"Used for multiple choice questions. Used for singleChoiceSelect, multipleChoiceSelect, singleChoiceRadio, multipleChoiceCheckboxes",
					dataType: "array",
					expanded: false,
					of: {
						name: "Option",
						dataType: "map",
						properties: {
							id: {
								name: "ID",
								dataType: "string",
								hideFromCollection: true,
								Field: CustomId,
							},
							label: {
								name: "Label",
								description: "Human readable label for the answer choice.",
								dataType: "string",
							},
							value: {
								name: "Value",
								description: "The value of this option.",
								dataType: "string",
							},
							checked: {
								name: "Default Value",
								description: "Should the checkbox be selected by default?",
								dataType: "boolean",
								defaultValue: false,
							},
						},
					},
				}
			}
			return null // Exclude the property entirely if it's not a choice type
		},
		repeatingField: {
			name: "Repeating Field",
			dataType: "map",
			previewProperties: ["isEnabled"],
			expanded: false,
			properties: {
				isEnabled: {
					name: "Allow Repeat",
					description: "The question allows multiple answers.",
					dataType: "boolean",
				},
				minRepeats: {
					name: "Min Value",
					description: "Require a minimum number of answers.",
					dataType: "number",
					clearable: true,
					hideFromCollection: true,
				},
				maxRepeats: {
					name: "Max Value",
					description: "Limit the number of answers.",
					dataType: "number",
					clearable: true,
					hideFromCollection: true,
				},
				buttonLabel: {
					name: "Button Label",
					description: "The label for the 'add another answer' button.",
					dataType: "string",
					clearable: true,
					hideFromCollection: true,
				},
				legend: {
					name: "Legend",
					description: "The label that appears above each Repeating Field fieldset",
					dataType: "string",
					clearable: true,
					hideFromCollection: true,
				},
			},
		},
		rules: {
			name: "Validations",
			dataType: "map",
			description: "https://react-hook-form.com/docs/useform/register#options",
			longDescription: "Form validations from react-hook-forms rules, which extend HTML validation attributes.",
			expanded: false,
			properties: {
				required: {
					name: "Required",
					dataType: "boolean",
					description: "https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/required",
				},
			},
		},
		dateUpdated: DateUpdated,
		dateCreated: DateCreated,
	},
})
