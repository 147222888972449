import { buildCollection } from "@firecms/core"
import type { ADMIN } from "types"
import { DuplicateCheckName } from "../components/DuplicateCheckName"
import AddressProperty from "../properties/AddressProperty"
import DateUpdated from "../properties/DateUpdated"
import DateCreated from "../properties/DateCreated"

export const _FirmsCollection = buildCollection<ADMIN.Firm>({
	id: "_firms",
	name: "Law Firms (Private)",
	singularName: "Law Firm (Private)",
	description: "The law firms representing the class members in a settlement.",
	path: "_firms",
	icon: "corporate_fare",
	group: "private",
	selectionEnabled: true,
	textSearchEnabled: true,
	defaultSize: "xs",
	initialSort: ["name", "asc"],
	permissions: () => ({
		read: true,
		edit: true,
		create: true,
		delete: true,
	}),
	properties: {
		name: {
			name: "Name",
			description: "",
			dataType: "string",
		},
		// @ts-ignore
		address: AddressProperty,
		dateUpdated: DateUpdated,
		dateCreated: DateCreated,
	},
	additionalFields: [DuplicateCheckName],
})
