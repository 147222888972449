import { useEffect, useState } from "react"
import type React from "react"
import type { FieldProps } from "@firecms/core"
import type { ImageAuthor } from "types/Enums/ImageAuthor"
import { ImagePreview, useStorageSource } from "@firecms/core"
import {
	Table,
	TableBody,
	TableHeader,
	TableRow,
	TableCell,
	Skeleton,
	ExpandablePanel,
	Button,
	TextField,
	ImageIcon,
} from "@firecms/ui"
import { imgPath } from "utils/imgUrl"
import type { Image } from "types/Components/Image"
import ExpandableTitle from "../ExpandableTitle"

const ImagePreviewGrid: React.FC<FieldProps<Image>> = ({ setValue, value, customProps }) => {
	const storage = useStorageSource()
	const { entityId, images } = customProps
	const authors: ImageAuthor[] = ["o", "s", "f"]
	const options: number[] = [0, 1, 2, 3]
	const [imageUrls, setImageUrls] = useState<Map<string, string>>(new Map())

	useEffect(() => {
		authors.forEach(author => {
			options.forEach(option => {
				const storagePath = imgPath(entityId, author, option, "outpaint")
				storage
					.getDownloadURL(storagePath)
					.then(res => {
						setImageUrls(prevUrls => new Map(prevUrls).set(`${author}-${option}`, res.url || ""))
					})
					.catch(error => console.error("Error fetching image URL:", error))
			})
		})
	}, [storage])

	const handleImageClick = (author: ImageAuthor, option: number) => {
		const matchingImage = images.find((img: Image) => img.author === author && img.option === option)
		setValue({
			...value,
			author,
			option,
			alt: matchingImage?.alt || "",
		})
	}

	return (
		<ExpandablePanel
			initiallyExpanded={false}
			title={
				<ExpandableTitle title="Image">
					<ImageIcon size="small" />
				</ExpandableTitle>
			}
		>
			<Table>
				<TableHeader>
					<TableCell header>Author</TableCell>
					{authors.map(author => (
						<TableCell header key={author}>
							{author === "o" && "OpenAi"}
							{author === "s" && "Stable Diffusion"}
							{author === "f" && "Flux"}
						</TableCell>
					))}
				</TableHeader>
				<TableBody>
					{options.map(option => (
						<TableRow key={option}>
							<TableCell>{option}</TableCell>
							{authors.map(author => {
								const key = `${author}-${option}`
								const url = imageUrls.get(key)
								const selected = (value.option === option && value.author === author) || false
								return (
									<TableCell key={key}>
										{url ? (
											<Button
												onClick={() => handleImageClick(author, option)}
												variant={selected ? "filled" : "text"}
												className="p-0"
											>
												<ImagePreview size="medium" url={url} />
											</Button>
										) : (
											<Skeleton width={200} height={200} />
										)}
									</TableCell>
								)
							})}
						</TableRow>
					))}
				</TableBody>
			</Table>
			<TextField value={value.alt} multiline readOnly />
		</ExpandablePanel>
	)
}
export default ImagePreviewGrid
