import { buildCollection, buildProperty } from "@firecms/core"
import type { ADMIN } from "types"
import { DuplicateCheckName } from "../components/DuplicateCheckName"
import DateUpdated from "../properties/DateUpdated"
import DateCreated from "../properties/DateCreated"

export const _DefendantsCollection = buildCollection<ADMIN.Defendant>({
	id: "_defendants",
	name: "Defendants (Private)",
	singularName: "Defendant (Private)",
	description: "The companies or people being sued.",
	path: "_defendants",
	icon: "shield",
	group: "private",
	defaultSize: "xs",
	selectionEnabled: true,
	textSearchEnabled: true,
	initialSort: ["name", "asc"],
	permissions: () => ({
		read: true,
		edit: true,
		create: true,
		delete: true,
	}),
	properties: {
		name: {
			name: "Name",
			description: "What is the official company name?",
			longDescription: "e.g. 'Meta Platforms, Inc.'",
			dataType: "string",
		},
		image: buildProperty({
			dataType: "string",
			name: "Image",
			storage: {
				storagePath: context => {
					return `defendants/${context.entityId}/`
				},
				acceptedFiles: ["image/*"],
				metadata: {
					cacheControl: "max-age=1000000",
				},
				fileName: "image",
			},
		}),
		dateUpdated: DateUpdated,
		dateCreated: DateCreated,
	},
	additionalFields: [DuplicateCheckName],
})
