import { buildCollection } from "@firecms/core"
import QATypeProperty from "../../properties/QATypeProperty"
import type { ADMIN } from "types"
import DateCreated from "../../properties/DateCreated"
import DateUpdated from "../../properties/DateUpdated"
import AnswerContent from "../../properties/AnswerContent"

export const SpecificAnswersCollection = buildCollection<ADMIN.Answer>({
	id: "specificAnswers",
	name: "Specific Answers",
	singularName: "Specific Answer",
	description: "List of the user's specfic answers",
	path: "specificAnswers",
	icon: "radio_button_checked",
	defaultSize: "m",
	initialSort: ["dateCreated", "desc"],
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	properties: {
		question: {
			description: "the question that this answers.",
			dataType: "reference",
			path: "generalQuestions",
			previewProperties: ["text", "type"],
		},
		type: QATypeProperty,
		// @ts-ignore
		content: AnswerContent,
		dateCreated: DateCreated,
		dateUpdated: DateUpdated,
	},
})
